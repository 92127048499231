<template>
  <div>
    <CButton
      v-if="false"
      @click="show"
    >SHOW
    </CButton>
    <CCard>
      <CCardHeader>
        <div class="card-header-actions">
          <div style="cursor:pointer" @click="$store.state.showMemberList = !$store.state.showMemberList">
            <CIcon :name="`cil-chevron-${$store.state.showMemberList ? 'bottom' : 'top'}`"/>
          </div>
        </div>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol lg="3">
            <my-logo/>
          </CCol>
          <CCol lg="9">
            <CRow style="position: relative;">
              <CCol col="12">
                <create-job
                  v-bind:client_id="item.client_id"
                  v-bind:title.sync="item.title"
                  v-bind:job_detail.sync="item.job_detail"
                  v-bind:start_date.sync="item.start_date"
                  v-bind:end_date.sync="item.end_date"
                  v-bind:finish_date.sync="item.finish_date"
                  v-bind:price.sync="item.price"
                  v-bind:status="item.status"
                  v-on:update:status="item.status = $event.target.value"
                  v-bind:worker_id.sync="item.worker_id"
                  v-bind:category_id="item.category_id"
                  v-on:update:category_id="item.category_id = $event.target.value"
                  v-bind:is_disabled="false"
                  v-on:update:addLinks="addLinks($event)"
                  v-on:update:links="item.links = $event"
                  v-bind:links="item.links"
                />
              </CCol>
              <CCol
                @click="getTemplates"
                col="12" class="offset-8" style="position: absolute; top:-10px;">
                <CDropdown
                  size=""
                  placement="bottom-end"
                  toggler-text="履歴から作成"
                  color="link"
                  class="m-0 d-inline-block"
                  :caret="true"
                >
                  <CDropdownItem
                    v-for="template in templates"
                    @click="getTemplateJob(template['id'])"
                  >{{template["title"]}}</CDropdownItem>
                </CDropdown>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter>
        <CRow>
          <CCol class="col-lg-2">
            <CButton
              style="width:100%"
              variant="outline"
              @click="moveToListJobs"
              size="sm"
              color="warning"
            >キャンセル
            </CButton>
          </CCol>
          <CCol class="col-lg-2 offset-lg-8">
            <CButton
              style="width:100%"
              variant="outline"
              @click="createJob"
              size="sm"
              color="primary"
            >送信
            </CButton>
          </CCol>
        </CRow>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import Comm from '@/views/v1/lib/Comm.js'
import CreateJob from "@/views/v1/components/CreateJob"
import MyLogo from "@/views/v1/components/MyLogo"

export default {
  name: "CreateJob",
  mixins: [Comm],/* ここでミックスインを追加 */
  props: {},
  mounted() {
  },
  components: {
    "create-job": CreateJob,
    "my-logo": MyLogo,
  },
  data() {
    return {
      item: {
        title: '',
        start_date: this.getShortDate(null, 'JST', 8),
        end_date: this.getShortDate(null, 'JST', 8),
        finish_date: this.getShortDate(null, 'JST', 8),
        client_id: this.$store.state.user_id,
        family_id: this.$store.state.family_id,
        price: 1500,
        status: 1,
        job_detail: '',
        worker_id: 0,
        category_id: 1,
        links: [{title: "参考ページ", href: ""}]
      },
      templates: false
    }
  },
  computed: {},
  methods: {
    show(e) {
      console.log(e)
    },
    showEvent(e) {
      console.log(e)
    },
    updateEvent(e) {
      console.log(e)
    },
    async getTemplates(){
      this.templates = await this._getTemplates(100, 0)
    },
    async getTemplateJob(id){
      const item = this.templates.find(item => item.id === id)
      this.item.title = item.title
      this.item.job_detail = item.job_detail
      this.item.price = item.price
      this.item.category_id = item.category_id
      this.item.links = item.links
    },
    moveToListJobs() {
      this.$router.push({name: 'ListJobs'})
    },
    addLinks(e) {
      if (this.item.links.length - 1 === e) {
        this.item.links.push({title: "参考ページ", href: ""})
      } else {
        this.item.links.splice(e, 1)
      }
    },
    async createJob() {
      // this.item["start_date"] = this.item["start_date"]
      // this.item["end_date"] = this.item["end_date"]
      // this.item["finish_date"] = this.item["finish_date"]
      // this.item["client_id"] = Number(this.item["client_id"])
      // this.item["worker_id"] = Number(this.item["worker_id"])
      // this.item["category_id"] = Number(this.item["category_id"])
      // this.item["price"] = Number(this.item["price"])
      this.item.links.map((link, index) => {
        if (link['href'] === "" || link['title'] === "") {
          this.item.links.splice(index, 1)
        }
      })
      console.log(this.item)
      const response = await this._createItem(this.item)
      console.log(response)
      await this.$router.push({name: 'ListJobs'})
    }
  }
}
</script>

<style scoped>
</style>
